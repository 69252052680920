import toggleVisibility from "./toggle-visibility";
import { activeClass } from "./active-class";

const Accordion = () => {
  const accordionHandler = (accordion) => {
    const accordionAriaControls = accordion.getAttribute("aria-controls");
    const accordtionPanel = document.getElementById(accordionAriaControls);
    const accordionItem = accordion.closest("[data-accordion-item]");
    toggleVisibility(accordtionPanel);
    accordionItem.classList.toggle(activeClass);

    accordion.setAttribute("aria-expanded", accordion.getAttribute("aria-expanded") === "false" ? true : false);
  };

  const accordionsTriggers = document.querySelectorAll("[data-accordion-trigger]");
  accordionsTriggers.forEach((accordionTrigger) => {
    accordionTrigger.addEventListener("click", () => {
      accordionHandler(accordionTrigger);
    });
  });
};
export default Accordion;
