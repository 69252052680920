import Accordion from "./modules/accordion";
import Tabs from "./modules/tabs";
import Animate from "./modules/animate";
import Counter from "./modules/counter";
import VideoAutoplay from "./modules/video-autoplay";
import Preload from "./modules/preload";
import Dialog from "./modules/dialog";
import Header from "./modules/header";
import Offices from "./modules/offices";
import Dropdown from "./modules/dropdown";

Preload();

Accordion();

Tabs();

VideoAutoplay();

Animate();

Counter();

Dialog();

Header();

Offices();

Dropdown();
