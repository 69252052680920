import toggleVisibility from "./toggle-visibility";
import { activeClass } from "./active-class";

const Offices = () => {
  const officeTogglesSelector = "[data-office-toggle]";
  const officeToggles = document.querySelectorAll(officeTogglesSelector);
  if (!officeToggles.length) return;
  const officeMapSelector = "data-office-map";

  const officeListCurrent = document.querySelector("[data-office-current]");

  officeToggles.forEach((officeToggle) => {
    officeToggle.onclick = () => {
      if (officeToggle.classList.contains(activeClass)) return;

      const officeMapFor = officeToggle.getAttribute("data-office-map-for");
      const activeOfficeToggle = document.querySelector(`${officeTogglesSelector}.${activeClass}`);

      officeListCurrent.textContent = officeToggle.textContent;

      activeOfficeToggle.classList.remove(activeClass);
      officeToggle.classList.add(activeClass);

      toggleVisibility(activeOfficeToggle.nextElementSibling);
      toggleVisibility(officeToggle.nextElementSibling);

      document.querySelector(`[${officeMapSelector}].${activeClass}`).classList.remove(activeClass);
      document.querySelector(`[${officeMapSelector}=${officeMapFor}]`).classList.add(activeClass);
    };
  });
};
export default Offices;
