import { activeClass } from "./active-class";

function Dialog() {
  // Get DOM elements
  const openButtons = document.querySelectorAll("[data-dialog-open]");

  // Return early if any required element is missing
  if (!openButtons.length) {
    return;
  }

  // Set the anchor point to the button that was used to open the dialog box so that we can return the focus to it later.
  let focusAnchor;

  // Set focusable elements
  let focusableElements;
  let firstFocusableElement;
  let lastFocusableElement;

  function updateDialogContent(dialogContainer, openButton) {
    const containerForImage = dialogContainer.querySelector("[data-dialog-container-for-image]");
    const containerForTitle = dialogContainer.querySelector("[data-dialog-container-for-title]");
    const containerForSubtitle = dialogContainer.querySelector("[data-dialog-container-for-subtitle]");
    const containerForContent = dialogContainer.querySelector("[data-dialog-container-for-content]");

    const image = openButton.querySelector("[data-dialog-image]")?.innerHTML;
    const title = openButton.querySelector("[data-dialog-title]")?.innerHTML;
    const subtitle = openButton.querySelector("[data-dialog-subtitle")?.innerHTML;
    const content = openButton.querySelector("[data-dialog-content]")?.innerHTML;

    containerForContent.innerHTML = content ?? "";
    containerForTitle.innerHTML = title ?? "";
    containerForSubtitle.innerHTML = subtitle ?? "";
    containerForImage.innerHTML = image ?? "";
  }

  // Add event listeners
  openButtons.forEach((openButton, currentOpenButtonKey) => {
    openButton.addEventListener("click", (e) => {
      e.preventDefault();
      focusAnchor = openButton;

      const dialogContainer = document.querySelector("[data-dialog]");
      const dialogHolder = document.querySelector("[data-dialog-holder]");
      const closeButton = dialogContainer.querySelector("[data-dialog-close]");
      const backdrop = dialogContainer.querySelector("[data-dialog-backdrop]");
      [backdrop, closeButton].forEach((element) => {
        element.addEventListener("click", closeDialog);
      });

      const dialogNavigation = dialogContainer.querySelectorAll("[data-dialog-navigation]");

      const totalOpenButtons = openButtons.length - 1;
      let openButtonKey = currentOpenButtonKey;
      dialogNavigation.forEach((button) => {
        button.addEventListener("click", () => {
          const dialogNavigationAction = button.getAttribute("data-dialog-navigation");
          if (dialogNavigationAction == "next") {
            openButtonKey++;
            if (openButtonKey > totalOpenButtons) {
              openButtonKey = 0;
            }
          } else {
            openButtonKey--;
            if (openButtonKey < 0) {
              openButtonKey = totalOpenButtons;
            }
          }
          dialogScrollTop();
          updateDialogContent(dialogContainer, openButtons[openButtonKey]);
        });
      });

      updateDialogContent(dialogContainer, openButton);
      openDialog();

      // Get focusable elements inside dialog container
      focusableElements = dialogContainer.querySelectorAll('button, a[href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      firstFocusableElement = focusableElements[0];
      lastFocusableElement = focusableElements[focusableElements.length - 1];
      openButton.addEventListener("keydown", focusFirstElementIfMouseOpen, {
        once: true,
      });

      openButton.addEventListener("keydown", focusFirstElement);

      dialogContainer.addEventListener("keydown", focusTrap);

      // Open dialog and focus first element
      function openDialog() {
        document.documentElement.classList.add("is-locked");
        dialogContainer.classList.add(activeClass);
        document.addEventListener("keydown", closeWithEscHandler);
        dialogScrollTop();
      }

      // Close dialog and and return focus
      function closeDialog() {
        document.documentElement.classList.remove("is-locked");
        dialogContainer.classList.remove(activeClass);
        document.removeEventListener("keydown", closeWithEscHandler);
        focusAnchor.focus({
          preventScroll: true,
        });
      }

      function dialogScrollTop() {
        dialogHolder.scrollTop = 0;
      }

      // Close dialog with Esc key
      function closeWithEscHandler(e) {
        if (e.code === "Escape") closeDialog();
      }

      // Set focus on first element
      function focusFirstElement(e) {
        if (e.code === "Enter" || e.code === "Space")
          dialogContainer.addEventListener(
            "transitionend",
            () => {
              firstFocusableElement.focus();
            },
            { once: true }
          );
      }

      // Set focus on first element and trap focus when opening dialog with mouse and then trying to navigate with keyboard
      function focusFirstElementIfMouseOpen(e) {
        if (e.code === "Tab") {
          firstFocusableElement.focus();
          focusTrap(e);
        }
      }

      // Trap focus inside dialog container
      function focusTrap(e) {
        if (e.code === "Tab" && e.shiftKey && document.activeElement === firstFocusableElement) {
          e.preventDefault();
          lastFocusableElement.focus();
        } else if (e.code === "Tab" && !e.shiftKey && document.activeElement === lastFocusableElement) {
          e.preventDefault();
          firstFocusableElement.focus();
        }
      }
    });
  });
}

export default Dialog;
