import { activeClass } from "./active-class";

function Search() {
  const isChangingClass = "is-changing";

  const dropdownToggleSelector = "data-dropdown-toggle";

  const searchToggle = document.querySelector("[data-search-popup-toggle]");

  const searchPopup = document.querySelector("[data-search-popup]");

  const searchInput = searchPopup.querySelector("[data-search-input]");

  const overlayMenu = document.querySelector("[data-overlay-menu]");

  const curtain = document.querySelector("[data-site-header-curtain]");

  const overlayMenuLinks = overlayMenu.querySelectorAll("a");

  const toggleButton = document.querySelector("[data-overlay-menu-toggle]");

  const isSearchActive = () => {
    return searchPopup.classList.contains(activeClass);
  };

  const isOverlayMenuActive = () => {
    return overlayMenu.classList.contains(activeClass);
  };

  function hideOverlayMenu() {
    if (isOverlayMenuActive()) overlayMenuHandler();
  }

  function hideSearch() {
    if (isSearchActive()) searchHandler();
  }

  function searchHandler() {
    hideOverlayMenu();
    searchPopup.classList.toggle(activeClass);
    searchToggle.classList.toggle(activeClass);
    if (isSearchActive()) {
      searchPopup.addEventListener("transitionend", focusSearchInput, { once: true });
    } else {
      searchPopup.removeEventListener("transitionend", focusSearchInput);
    }
  }

  function focusSearchInput() {
    searchInput?.focus();
  }

  searchToggle.addEventListener("click", () => {
    searchHandler();
  });

  curtain.addEventListener("click", () => {
    hideSearch();
  });

  //Overlay Menu

  const liItems = document.querySelectorAll("[data-animate-elements] > li");
  liItems?.forEach((li, key) => {
    li.style.setProperty("--i", key);
  });

  function overlayMenuHandler() {
    hideSearch();
    document.documentElement.classList.toggle("site-header-is-locked");
    overlayMenu.classList.toggle(activeClass);
    overlayMenu.classList.add(isChangingClass);
    overlayMenu.addEventListener("transitionend", function () {
      overlayMenu.classList.remove(isChangingClass);
    });
    toggleButton.classList.toggle(activeClass);
  }

  //Elements clicks
  toggleButton.addEventListener("click", () => {
    overlayMenuHandler();
  });

  overlayMenuLinks.forEach((link) => {
    link.addEventListener("click", () => {
      if (link.href === "") {
        const nextElement = link.nextElementSibling;
        if (nextElement.hasAttribute(dropdownToggleSelector)) {
          nextElement.click();
        }
      }
    });
  });

  //Escape click
  document.addEventListener("keydown", (e) => {
    if (e.key == "Escape") {
      hideSearch();
      hideOverlayMenu();
    }
  });

  //Mobile submenu toggle
  const dropdownToggles = document.querySelectorAll("[" + dropdownToggleSelector + "]");
  dropdownToggles.forEach((dropdownToggle) => {
    dropdownToggle.addEventListener("click", () => {
      dropdownToggle.classList.toggle("dropdown-toggle--active");
      dropdownToggle.nextElementSibling.classList.toggle("dropdown-responsive--visible");
    });
  });

  //Dropdown Title
  const menuItemsHasChildren = document.querySelectorAll(".site-menu > .menu-item-has-children");
  if (menuItemsHasChildren.length) {
    menuItemsHasChildren.forEach((menuItemHasChildren) => {
      const firstLink = menuItemHasChildren.querySelector("a:first-child");
      const dropdownTitle = menuItemHasChildren.querySelector("[data-dropdown-title]");
      if (dropdownTitle && firstLink) dropdownTitle.textContent = firstLink.textContent;
    });
  }
}

export default Search;
