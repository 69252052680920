import { activeClass } from "./active-class";

const Dropdown = () => {
  const dropdowns = document.querySelectorAll("[data-dropdown]");
  if (!dropdowns.length) return;

  dropdowns.forEach((dropdown) => {
    const dropdownButton = dropdown.querySelector("[data-dropdown-button]");

    const dropdownHandler = (action) => {
      switch (action) {
        case "show":
          dropdown.classList.add(activeClass);
          document.addEventListener("click", closeWhenClickedOutside);
          break;
        case "hide":
          dropdown.classList.remove(activeClass);
          document.removeEventListener("click", closeWhenClickedOutside);
          break;
      }
    };

    function closeWhenClickedOutside(event) {
      if (event.target !== dropdownButton) {
        dropdownHandler("hide");
      }
    }

    dropdownButton.onclick = () => {
      if (dropdown.classList.contains(activeClass)) {
        dropdownHandler("hide");
      } else {
        dropdownHandler("show");
      }
    };
  });
};
export default Dropdown;
